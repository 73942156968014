
  
  .sc-ion-modal-md-h.pass-modal  {
    --height: 160px !important;
  }
  
  @media only screen and (min-width: 768px) and (min-heighat: 768px) {
    .sc-ion-modal-md-h.pass-modal {
      --width: 300px;
      --height: 160px !important;
    }
  }