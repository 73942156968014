.dividerQuestion {
    font-weight: 900;
}
.textAnswer{
    padding: 8px;
}
.divFAQ{
    padding: 12px;
}
.spanFAQ{
    border: 5px;
}
.parrafo{
    padding: 12px;
}