.virtual-scroll-row {
  display: grid;
  grid-template-columns: 72px 1fr;
  grid-gap: 10px;
  border-bottom-style: solid;
  border-bottom-width: 1px solid #000;
  padding-top: 16px;
  padding-bottom: 16px;
}

.column-one {
  grid-column: 1;
  grid-row: 1;
  margin: auto;
}
.column-two {
  grid-column: 2;
  grid-row: 1;
}

.selection-area {
  overflow: auto;
  max-height: 300px;
}