ion-picker>.picker-wrapper {
    background-color: #1e2225 !important;
}

.picker-opt {
    color: white !important;
}

.picker-opt.picker-opt-selected {
    color: greenyellow !important;
}

ion-datetime {
    color: var(--ion-color-dark) !important;
}
.picker-button.sc-ion-picker-md{
    color: greenyellow;
}
.segmentButton{
    color: var(--ion-color-dark) !important;
    --color-checked: rgb(205 222 0);
}
.segmentButton:hover{
    --color-checked: rgb(0, 0, 0);
}
.alert-button.sc-ion-alert-md {
color: #1e2225;
}
