.logo-login {
  /*min-height: max-content;
   margin: auto;*/
  background: var(--ion-color-primary);
  
}


.input-icon {
  margin-right: 15px;
}
ion-input.ios.input-secondary-bg input.native-input.sc-ion-input-ios::placeholder {
  padding-left: 15px !important;
}

ion-router-outlet.header-margin-top{
  padding-top: 56px !important;
  margin-top: 56px !important;
}

ion-title.ios {
  color: var(--ion-color-secondary) !important;
}

.full-screen {
  height: 100%;
  width: 100%;
}


.form {
  background: var(--ion-color-secondary);
  
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  
}

.img-logo {
  max-width: 200px;
  max-height: 200px;
  margin: 0 auto;
}

.img-form {
  max-width: 200px;
  margin: 0 auto 25px;
}

.base-bg {
  background: var(--ion-color-primary);

}

.secondary-bg {
  background: var(--ion-color-secondary);

}

.logo-margin-y {
  margin: auto 0;
}

top {
  height: max-content;
}

@media (max-width: 768px) {
  .logo-margin-y {
    margin: 0;
  }

  .top {
    height: 100% !important;
  }
}

.grid-height {
  height: 100%;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.primary-bg {
  --background: var(--ion-color-primary)  !important;
}


.secondary-bg {
  --background: var(--ion-color-secondary)  !important;
}

.input-secondary-bg{ 
  border-radius: 10px; 
  --color: var(--ion-color-secondary-contrast) !important;
  --placeholder-color: var(--ion-color--contrast) !important;
  border-width: 1px;
  border-style: solid;
  border-color: #92a8d1;
  margin-left: 15px;
}
.input-secondary-bg:hover {
  border-color: #cdde00;
  --placeholder-color: var(--ion-color-secondary-contrast) !important;
}

.native-input.sc-ion-input-md:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px rgb(16, 24, 32) inset; /* Change the color to your own background color */
  -webkit-text-fill-color: rgb(255, 255, 255);
  border-radius: 10px;
}

.round-25 {
  --border-radius: 25px !important;
  margin: 10px auto;
}


.margin-y-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.round-0 {
  border-radius: 0px !important;
}
.message{
  background-color: rgb(39, 39, 39);
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  text-align: center;
  border-radius: 2em;
  margin: .5em;
  font-weight: bold;
  color:#e1e1e1;
  justify-content: center;
  align-items: center;
}
.native-input.sc-ion-input-ios:-webkit-autofill{
  -webkit-box-shadow:0 0 0 50px rgb(16, 24, 32) inset; /* Change the color to your own background color */
  -webkit-text-fill-color: rgb(255, 255, 255);
  border-radius: 10px;
}

