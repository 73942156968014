.card-icon {
  display: inline-block;
  --background: var(--ion-color-primary) !important;
  z-index: 2;
  font-size: 36px;
  line-height: 90px;
  width: 90px;
  height: 90px;
  text-align: center;
  margin-bottom: 1px;
  padding: 15px 5px 5px 5px !important;
  margin-top: -30px !important;
}

body.dark ion-card.card-icon ion-icon {
  color: var(--ion-color-secondary) !important;
}

.card-img {
  display: inline-block;
  --background: rgba(255, 255, 255, 0.1) !important;
  background-color: rgba(255, 255, 255, 0.1)  !important;
  z-index: 3;
  font-size: 36px;
  line-height: 90px;
  width: 90px;
  height: 90px;
  padding: 0 !important;
  text-align: center;
  margin-bottom: 1px;
  border-radius: 45px; 
  box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: -20px !important;
  margin-left: calc(50% - 45px) !important;
}

.margin-top-20 {
  padding-top: 50px !important;
}

.card-container {
  margin: 34px 0px !important;
  --background: #fff;
  position: relative;
  z-index: 1;
  overflow: visible;
}

.card-container.no-margin {
  margin: 0px !important;
}

body.dark .card-container {
  --background: var(--ion-color-secondary-tint);
}

ion-card-title {
  color:  var(--ion-color-secondary);
}

body.dark ion-card-title {
  color:  #FFF;
}




ion-icon {
  color: var(--ion-color-secondary);
}
ion-card-header.card-header-style > ion-card-title{
  margin-top: 24px !important;
  font-weight: 600;
  letter-spacing: -0.4px;
}

ion-card-header > ion-card-title{
  font-weight: 600;
  font-size: medium;
  letter-spacing: -0.4px;
}
ion-card.information-card{
  box-shadow: none !important;
}

.ios .card-header-style {
  float: right;
  width: -webkit-calc(100% - 130px);
  width:    -moz-calc(100% - 130px);
  width:         calc(100% - 130px);
}
.md .card-header-style {
  float: right;
  width: -webkit-calc(100% - 110px);
  width:    -moz-calc(100% - 110px);
  width:         calc(100% - 110px);
}

.card-content-style {
  clear: both;
}