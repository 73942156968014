ion-menu {
  max-width: 300px !important;
}

.logo {
  width: 200px;
  height: 82px;
  margin-left: 15%;
  padding-bottom: 10px;
}

ion-menu ion-content,
ion-menu.ios ion-content {
  --background: var(--ion-color-secondary, var(--ion-background-color, #fff)) !important;
}

ion-list,
ion-menu-toggle ion-item {
  background: var(--ion-color-secondary) !important;
  --background: var(--ion-color-secondary) !important;
}
ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
  --background: var(--ion-color-secondary) !important;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list,
ion-menu.md ion-list#control-asistencia-list,
ion-menu.md ion-list#elemento32-list,
ion-menu.ios ion-list#inbox-list,
ion-menu.ios ion-list#control-asistencia-list,
ion-menu.ios ion-list#elemento32-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}


ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 20px;
  font-weight: 600;
  min-height: 20px;
  color: #FFF !important;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: var(--ion-color-primary) !important;
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-secondary) !important;
}

ion-menu.md ion-item.selected ion-label {
  color: var(--ion-color-secondary) !important;
}

ion-menu.md ion-item ion-icon {
  color: rgb(255, 255, 255);
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
  color: #FFF !important;
}
/* iOS CSS */

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-label {
  font-weight: 500;
  color: #FFF !important;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item.selected {  
  --background: var(--ion-color-primary) !important;
}

ion-menu.ios ion-item.selected ion-icon,
ion-menu.ios ion-item.selected ion-label {
  color: var(--ion-color-secondary) !important;
}

ion-menu.ios ion-list#inbox-list ion-list-header {  
  font-size: 20px;
  font-weight: 600;
  min-height: 20px;
  color: #FFF !important;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-img {
  min-height: 1px;
}
