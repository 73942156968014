/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #cdde00;
  --ion-color-primary-rgb: 205, 222, 0;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #b4c300; /** 12% **/
  --ion-color-primary-tint: #d2e119; /** 10% **/

  /** secondary **/
  --ion-color-secondary: #101820;
  --ion-color-secondary-rgb: 16, 24, 32;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0e151c;
  --ion-color-secondary-tint: #282f36;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;  
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}


  /*
   * Dark Colors
   * -------------------------------------------
   */

  body.dark {
    /** primary **/
    --ion-color-primary: #cdde00;
    --ion-color-primary-rgb: 205, 222, 0;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0, 0, 0;
    --ion-color-primary-shade: #b4c300; /** 12% **/
    --ion-color-primary-tint: #d2e119; /** 10% **/
  
    /** secondary **/
    --ion-color-secondary: #101820;
    --ion-color-secondary-rgb: 16, 24, 32;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0e151c;
    --ion-color-secondary-tint: #282f36;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    /* --ion-item-background: #000000; */
    --ion-item-background: var(--ion-color-secondary);

    --ion-card-background: #1c1c1d;
    
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;


  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  /* --ion-item-background: #1e1e1e; */
  --ion-item-background: var(--ion-color-secondary); 

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #1e1e1e;
}


.md body {
  --ion-background-color: #E5E1E6;
  --ion-background-color-rgb: 229, 225, 230;

  /* --ion-text-color: #323E48; */
  --ion-text-color: var(--ion-color-secondary);  
  --ion-text-color-rgb: 16, 24, 32;


  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #fff;

  --ion-toolbar-background: #101820;

  --ion-tab-bar-background: #e5e1e6;

  
  
  /*--ion-card-background: #1e1e1e;*/

  
}


ion-badge {
  z-index: 202 !important;
  position: absolute !important;
  top: -0.2rem !important;
  right: -0.2rem !important;
  opacity: 0.9 !important;
  overflow-y: visible !important;
}
ion-badge ~ ion-icon {
  margin-right: 1.2rem !important;
}

.list-md {
  padding-top: 0;
  padding-bottom: 0;
}
/*

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #101820;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
*/
.ionCardContent{
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: revert;
  max-height: 150px;
  margin-top: -15px;
}
.ionText{
  background:var(--ion-color-light);
  color: var(--ion-color-dark);
  padding: 5px;
  overflow: initial;
  text-overflow: clip;
  white-space: nowrap;
  }
.ionText:hover{
  background-color: #cdde00;
  color: rgb(0, 0, 0);
  cursor: pointer;
}
.iconSuccess{
  width: 70%;
  height: 70%;
}
.rowSearch{
  display: flex;
  align-items: center;
}
.ionSelecction{
  background: var(--ion-color-light);
  color: var(--ion-color-dark);
  padding: 2px;
  overflow: initial;
  text-overflow: clip;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
.buttonDelete{
  width: 127px;
  height: 2em;
  position: relative;
  margin-left: -150px;
}
.weekCol{
  display: flex;
  align-items: center;
  justify-content: center;
}
.colContentTime{
  padding: 16px;
  background-color: var(--ion-color-light);
  border-radius: 25px;
}

ion-item.accordion-header {
  --border-width: 0px 0px 1px 0px!important;
  --border-color: var(--ion-color-light-shade) !important;
  --background: var(--ion-color-light) !important;
}

ion-searchbar.search-bar {
  --border-radius: 25px !important;
}

ion-searchbar.search-bar input {
  background-color: #FFF !important;
  max-height: 35px;
}

ion-icon.searchbar-search-icon.sc-ion-searchbar-md {
  top: 0px;
  height: 35px;
}

ion-item.table-helper ion-button {
  margin: 4px 2px 4px 2px;
}



body.dark ion-searchbar.search-bar input {
  background-color: var(--ion-color-secondary) !important;
  max-height: 35px;
}

body.dark ion-searchbar.search-bar input::placeholder {
  color: var(--ion-color-dark);
}


body.dark ion-icon.searchbar-search-icon.sc-ion-searchbar-md{
  color: var(--ion-color-medium);
}

body.dark ion-button.button-outline ion-icon {
  color: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary) !important;
}


.only-padding-top {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 16px !important;
}

.full-height {
  height: 100% !important;
}

.no-marging {
  margin: 0px !important;
}

.vertical-line {
  width: 1px;
  height: 50px;
  background: var(--ion-color-medium);
  margin: auto;
}


ion-card.information-card > ion-card-content {
  height: calc(100% - 80) !important;
}


ion-card.information-card > ion-card-content > ion-grid {
  height: 100% !important;
}

ion-card.information-card > ion-card-content > ion-grid > ion-row.information-card-content {
  height: 100% !important;
}
/*
ion-row.information-card-content > ion-col {
  min-height: 50px;
  height: 50%;
}
*/

ion-row.information-card-content {
  margin: auto 0px !important;
}

ion-row.information-card-content > ion-col:nth-child(2) {
  border-left: 1px solid var(--ion-color-dark-tint) !important;
}

ion-popover {
  --width: 320px;
}

ion-popover > div > ion-content {
  max-height: 200px;
}




ion-item.modal-input {
  --background: transparent !important;
}

/* Error */

ion-item.modal-input.item-has-focus.error > ion-label.modal-label {
  color: var(--ion-color-danger) !important;
  font-weight: 600;
}

ion-item.modal-input.error > ion-label.modal-label {
  color: var(--ion-color-danger) !important;
  font-weight: 600;
}

ion-item.modal-input.error  > ion-icon.icon-input-margin {
  color: var(--ion-color-danger) !important;
}

ion-item.modal-input.item-has-focus.error  > ion-icon.icon-input-margin {
  color: var(--ion-color-danger) !important;
}

ion-item.modal-input.error  > ion-icon.icon-input-margin {
  color: var(--ion-color-danger) !important;
}

ion-item.modal-input.item-has-focus.error {
  --highlight-color-focused: var(--ion-color-danger) !important;
}

ion-item.modal-input.error {
  --border-color: var(--ion-color-danger) !important;
}


/* Light */

body ion-item.modal-input.item-has-focus > ion-label.modal-label {
  color: var(--ion-color-secondary) !important;
  font-weight: 600;
}

body ion-item.modal-input > ion-icon.icon-input-margin {
  color: var(--ion-color-medium) !important;
}

body ion-item.modal-input.item-has-focus > ion-icon.icon-input-margin {
  color: var(--ion-color-secondary) !important;
}

body ion-item.modal-input.item-has-focus {
  --highlight-color-focused: var(--ion-color-secondary) ;
}

/* Dark */
body.dark ion-item.modal-input.item-has-focus > ion-label.modal-label {
  color: var(--ion-color-primary) !important;
  font-weight: 600;
}


body.dark  ion-item.modal-input > ion-input {
  color: #FFF !important;
}

body.dark  ion-item.modal-input > ion-icon.icon-input-margin {
  color: #FFF !important;
}

body.dark  ion-item.modal-input.item-has-focus > ion-icon.icon-input-margin {
  color: var(--ion-color-primary) !important;
}

body.dark ion-item.modal-input.item-has-focus {
  --highlight-color-focused: var(--ion-color-primary) ;
}

ion-card.information-card ion-item {
  --background: transparent !important;
  max-height: 60px;
}

body ion-card.information-card ion-button {
  color: var(--ion-color-secondary) !important;
  --border-color: var(--ion-color-secondary) !important;
}

body.dark ion-card.information-card ion-button {
  color: var(--ion-color-primary) !important;
  --border-color: var(--ion-color-primary) !important;
}

body.dark #empleados-asistencia {
  background-color: var(--ion-color-dark-shade) !important;
}

ion-title.toolBarStart.center {
  text-align: center !important;
  color: white !important;
}

.full-width {
  width: 100%;
}

ion-list.bulk-load-errors {
  height: 60px;
}

.vertical-align {
  margin: auto 0px !important;
}

ion-button.bulk-load-empleado-status {
  margin: 0 !important;
}

.capitalize {
  text-transform: capitalize;
}

.input-error-message {
  font-size: 12px;
  color: var(--ion-color-danger) !important;
}
ion-content.inner-content {
  --padding-top: 54px !important;
}

/* Add button*/
.button-add-horizontal {
  width: 100px;
  height: 150px;
  color: transparent !important;
  background-color: transparent;
}

ion-button.button-add-horizontal::part(native) {
  padding: 0 !important;
}

/* Horizontal Scroll */
.row-horizontal-scroll {
  overflow-x: scroll !important;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}


.scroll-items {
  justify-content: flex-start; 
  flex-wrap: nowrap;
  overflow-x: scroll !important;
  overflow-y: hidden;
}
.scroll-items ion-col {
    display: flex;
    flex-wrap: nowrap;
}


.component-horizontal-scroll {
  width: 200px !important;
  height: 150px;
  text-overflow: ellipsis;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

ion-note {
  font-size: small;
}

ion-item.color-light {
  color: #222428;
  --background: #f4f5f8;
}