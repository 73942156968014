#pac-input {
  background-color: transparent;
  padding: 0 11px 0 40px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  text-overflow: ellipsis !important;
  background: url("../assets/img/map-outline.svg") no-repeat scroll 4px 0px !important;
  margin-bottom: 4px;
  white-space: nowrap;
  min-height: 36px;
}

input#pac-input:placeholder-shown {
  text-overflow: ellipsis !important;
}

input#pac-input::placeholder {
  text-overflow: ellipsis !important;
}

#pac-input:focus {
  /* padding-left: 30px;  /* Regular padding-left + 1. */
  width: calc(100% - 1px);
}

.pac-container {
  font-family: Roboto;
}

ion-col.google-maps-selector {
  padding-bottom: 16px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

button.info-window-btn {
  border: none;
  border-radius: 2px;
  padding: 12px 18px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  background-color: var(--ion-color-secondary);
  box-shadow: 0 0 4px #999;
  outline: none;
}

button.ripple {
  background-position: center;
  transition: background 0.8s;
}
button.ripple:hover {
  background: #282f36 radial-gradient(circle, transparent 1%, #282f36 1%) center/15000%;
}
button.ripple:active {
  background-color: var(--ion-color-medium);
  background-size: 100%;
  transition: background 0s;
}