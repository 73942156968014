.input-padding {
  padding-left: 28px;
}

.icon-input-margin {
  margin-top: 24px;
}

ion-item.rondin-item-hide {
  display: none !important;
  visibility: hidden;
}

ion-item.item-hide {
  display: none !important;
  visibility: hidden;
}