.invisible {
  visibility: hidden !important;
}

.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }

  .md .card-header-style,
  .ios .card-header-style {
    width: 100%;
  }
  
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
  .alert-wrapper.sc-ion-alert-md {
    max-width: 90vw !important;
    width: 900px !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
  .alert-wrapper.sc-ion-alert-md {
    max-width: 90vw !important;
    width: 900px !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
  .alert-wrapper.sc-ion-alert-md {
    max-width: 90vw !important;
    width: 900px !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
  .alert-wrapper.sc-ion-alert-md {
    max-width: 90vw !important;
    width: 900px !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

ion-toggle.dark-toogle {
  width: 30px;
  --handle-background: var(--ion-color-primary) url("../assets/icons/sunny.svg") no-repeat center / contain;
  --background: #cdde005e;
  --handle-background-checked: var(--ion-color-secondary) url("../assets/icons/moon.svg") no-repeat center / contain;
}




.md body .alert-checkbox-label.sc-ion-alert-md {
  color: var(--ion-color-secondary);
}

.md body.dark .alert-checkbox-label.sc-ion-alert-md {
  color: var(--ion-text-color);
}


.md body .alert-radio-label.sc-ion-alert-md {
  color: var(--ion-color-secondary);
}

.md body.dark .alert-radio-label.sc-ion-alert-md {
  color: var(--ion-text-color);
}


.md body [aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md    {
  color: var(--ion-color-primary);
}

.md body.dark [aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md    {
  color: var(--ion-color-primary);
}




.md body .action-sheet-button.sc-ion-action-sheet-md {
  color: var(--ion-color-secondary);
}

.md body .action-sheet-button.sc-ion-action-sheet-md:hover {
  background: var(--ion-color-primary);
}

.md body.dark .action-sheet-button.sc-ion-action-sheet-md {
  color: var(--ion-text-color);
}

.md body.dark .action-sheet-button.sc-ion-action-sheet-md:hover {
  background: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
  color: var(--ion-color-secondary);
}

/*
.md body .header-margin-top {
  margin-top: 56px !important;
}

.ios body .header-margin-top {
  margin-top: 44px !important;
}

.ios body .ios-margin {
  margin-top: 56px !important;
}
*/

.toolBarStart{
  color: white;
}




