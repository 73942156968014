.error-messages {
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
}

.loading-center-horizontal {
  margin-left: auto !important;
  margin-right: auto !important;
}

.full-width {
  width: 100% !important;
}

ion-popover.ios.date-picker::part(content) {
  /* width: 80vw;  */
  max-width: 350px !important;
  max-height: 350px !important;
}


@media only screen and (max-height: 650px) {
  ion-popover.ios.date-picker::part(content) {
    /* width: 80vw;  */
    max-width: 350px !important;
    height: 250px !important;
    max-height: 250px !important;
  }
  ion-popover.md.date-picker div#ion-react-wrapper {
    height: 300px !important;
  }
}

ion-popover#empleado-edad-picker::part(content) {
  top: unset !important;
  left: 0 !important;
  bottom: 0;
  min-width: 100vw !important;
  width: 100vw !important;
  border-radius: 0;
}

ion-datetime#empleado-edad-datetime {
  min-width: 100vw !important;
}

body ion-datetime#empleado-edad-datetime {
  background-color: #fff;
}

body.dark ion-datetime#empleado-edad-datetime {
  background-color: var(--ion-color-step-100, #ffffff);
  ;
}

ion-popover#empleado-edad-picker::part(arrow) {
  display: none !important;
}
/* CUSTOM DATE PICKER */


ion-popover.custom-picker::part(content) {
  top: unset !important;
  left: 0 !important;
  bottom: 0;
  min-width: 100vw !important;
  width: 100vw !important;
  border-radius: 0;
}

ion-datetime.custom-picker {
  min-width: 100vw !important;
}

body ion-datetime.custom-picker {
  background-color: #fff;
}

body ion-datetime.custom-time-picker {
  color: #fff !important;
}

body.dark ion-datetime.custom-picker {
  background-color: var(--ion-color-step-100, #ffffff);
}

ion-popover.custom-picker::part(arrow) {
  display: none !important;
}

#TeamMembers ion-col.graph-height, 
#TeamMembers ion-card.graph-height {
  height: 220px;
}